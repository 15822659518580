/* INDEX
🔘 GENERAL
🔴 TOPBAR
🟠 SIDEBAR
🟡 FOOTER
🟢 PAGE SPECIFIC
🔵 ADAPTIVITY
🟣
🟤
⚫
⚪

*/

/*🔘  GENERAL 🔘*/

html {
  scroll-behavior: smooth;
}

body {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  font-family: sans-serif;
  background-color: #e5e5e5;
  background-image: url("../images/lavender-field.png");
  background-repeat: no-repeat;
  background-position: center top;
  background-attachment: fixed;
  background-size: 100vw;
}

p {
  font-size: 1vw;
}

i {
  font-size: 1.5vw;
}

h1 {
  font-size: 2.5vw;
}

h2 {
  font-size: 1.5vw;
}

h3 {
  font-size: 1vw;
}

/*🔴  TOPBAR 🔴 */
#topbar {
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
  width: 100%;
  height: 7%;
  padding: 1px 1vw 0vw 1vw;
  background-color: #7169b9;
  border-bottom: 1px #ffffff solid;
}

.text-logo {
  text-shadow: 1px 1px 2px #474747;
  color: white;
}

.social-media {
  margin: 2vw;
}

.social-media i {
  color: white;
  padding: 0.5vw;
}

.top-bar-right {
  display: flex;
  align-items: center;
  color: white;
  font-size: 1.5rem;
  width: 5vw;
}

.top-bar-right i {
  padding: 0.5vw;
}

.top-bar-right form {
  border-radius: 4px;
  padding: 0.5vw 1vw 0.5vw 1vw;
}

.search-bar {
  font-size: 1vw;
}


/*🟠  SIDENAV  🟠*/

#side-nav {
  position: fixed;
  top: 7vh;
  height: 1.5vw;
  width: 3vw;
  z-index: 99;
  transition: width 2s linear;
}

/* .side-tab {
  transition: width 250ms linear;
  height: 2vw;
  width: 2.5vw;
  background-color: #6762a3;
  border: solid 2px white;
  margin: 0.5vw 0vw;
  padding: 20px 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: space-between;
  border-right: none;
  border-radius: 25px 0px 0px 25px;
  opacity: 0.6;
} */

#side-nav a {
  height: 1.5vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: space-between;
  margin: 0.5vw 0vw;
  padding: 20px 5px;
  background-color: #6762a3;
  border: solid 2px white;
  border-right: none;
  border-radius: 25px 0px 0px 25px;
  color: white;
  text-decoration: none;
  opacity: 0.6;
}

#side-nav h4 {
  display: none;
}

/* ----Hover---- */

#side-nav :hover {
  position: relative;
  opacity: 1;
  width: 6vw;
}

#side-nav :hover i {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  margin: 0;
}

#side-nav :hover h4 {
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
}

#side-nav :hover a {
  width: 6vw;
  height: 1.5vw;
}

/*🟢  HOME  🟢*/

/* #content {
} */

/*🟣 header */

#header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50vw;
  background: rgb(145, 137, 218);
  background: linear-gradient(
    0deg,
    rgba(145, 137, 218, 1) 0%,
    rgba(145, 137, 218, 1) 2%,
    rgba(145, 137, 218, 0) 21%
  );
}

.home-header-card {
  opacity: 0.7;
  position: relative;
  width: 50vw;
  height: 20vw;
  padding: 2vw;
  background-color: #e5e5e5;
  border-radius: 25px 25px 25px;
  -webkit-box-shadow: 0px 9px 19px 5px rgba(0, 0, 0, 0.34);
  box-shadow: 0px 9px 19px 5px rgba(0, 0, 0, 0.34);
}

.home-header-card h1 {
  color: rgb(53, 34, 78);
  text-align: center;
  margin: 0;
  padding: 0px 1.5vw;
  font-size: 4vw;
}

.home-header-card h2 {
  color: rgb(53, 34, 78);
  font-size: 2vw;
  text-align: center;
  margin: 0;
  padding: 0px 1vw 2vw;
}

.home-header-card p {
  color: rgb(53, 34, 78);
  font-size: 1.5vw;
  padding: 0px 1vw;
  width: 30vw;
}

.home-header-card i {
  color: rgb(53, 34, 78);
  position: absolute;
  opacity: 0.5;
  bottom: 3vw;
  left: 4vw;
  font-size: 6vw;
  width: 7vw;
}

#header img {
  z-index: 2;
  width: 45vw;
}

/*🟣 content */

#clinic {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 5vh 3vh;
  background-color: #e5e5e5;
}

.flip-card {
  background-color: transparent;
  width: 17vw;
  height: 25vw;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 17vw;
  height: 25vw;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front,
.flip-card-back {
  position: absolute;
  border-radius: 25px 25px 25px 25px;
  -webkit-box-shadow: 0px 10px 15px 6px rgba(0, 0, 0, 0.34);
  box-shadow: 0px 10px 15px 6px rgba(0, 0, 0, 0.34);
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

.flip-card-back {
  position: relative;
  width: 17vw;
  height: 25vw;
  background-color: #b5afe7;
  border: solid 10px #9b92e9;
  color: white;
  transform: rotateY(180deg);
}

.flip-card-back p {
  padding: 0vw 1vw;
}

.flip-card-clinic-info {
  color: white;
  background-color: #9b92e9;
  position: absolute;
  bottom: 1vw;
  padding: 2px;
  margin: 0.5vw;
  width: 16vw;
  height: 4vw;
  border-radius: 25px;
}

.flip-card-clinic-info:hover {
  background-color: #83cf94;
}

.flip-card-front {
  width: 17vw;
  height: 25vw;
  margin-bottom: 0px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  background-color: rgb(243, 243, 243);
}

.flip-card-front img {
  padding: 1vw;
  width: 13vw;
  border-radius: 50%;
}

.flip-card-front h2 {
  margin: 0px;
}

.flip-card-front p {
  width: 12vw;
  height: 4vw;
  padding-top: 0vw;
  padding-bottom: 1vw;
}

/*🟣 clinic round card */

.clinic-round-card {
  position: absolute;
  left: 18vw;
  background-color: #b5afe7;
  border-radius: 50% 50%;
  width: 17vw;
  height: 17vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.clinic-round-card i {
  color: white;
  padding: 0.5vw;
}

.clinic-round-card h2 {
  color: white;
  text-align: center;
  margin: 5px;
}

.clinic-round-card p {
  color: white;
  text-align: center;
  padding: 0.5vw 1vw;
  margin: 0px;
}

/*🟣 curves */

.curved-up {
  position: relative;
  margin: 0px;
  background-color: #e5e5e5;
}

.curved-bottom {
  margin: 0px;
  background-color: #9189da;
}

/*🟢  ABOUT  🟢*/

#about {
  display: flex;
  flex-direction: column;
  background-color: #9189da;
  color: white;
  font-size: 1.1vw;
  font-family: Georgia, "Times New Roman", Times, serif;
}

#about h1 {
  color: white;
  display: flex;
  justify-content: start;
  align-items: baseline;
  margin: 0vw 15vw 3vw 0vw;
}

.info {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #9189da;
  color: white;
}

.info p {
  width: 70vw;
}

/*🟢  CONTACT  🟢*/

.contact-curve {
  background-color: #e5e5e5;
  margin: 0;
  padding: 0;
}

#contact {
  margin: 0;
  padding: 20px;
  background-color: #e5e5e5;
  color: #554f7e;
}

#contact h1 {
  display: flex;
  justify-content: start;
  align-items: baseline;
  margin: 0vw 15vw 3vw 0vw;
}

#contact p {
  margin: auto;
  padding-bottom: 30px;
  width: 70vw;
}

#content-box {
  margin: 1vw;
  margin-top: 6vw;
  background-color: #9189da;
  box-shadow: 0px 7px 20px;
  border: 1px white solid;
}

.content-wrap {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  background-color: #e5e5e5;
  margin: 2vw;
  height: 30vw;
}

.contact-wrap {
  border-radius: 1vw;
  background-color: rgb(245, 245, 245);
  -webkit-box-shadow: 0px 18px 28px 5px rgba(0, 0, 0, 0.48);
  box-shadow: 0px 18px 28px 5px rgba(0, 0, 0, 0.48);
}

/*🟣 middle - contact info */

.contact-info {
  display: flex;
  width: 25vw;
  height: 25vw;
}

.contact-info-details {
  height: 25vw;
  display: flex;
  justify-content: space-around;
  align-content: space-around;
  flex-direction: column;
}
.contact-info-details h3 {
  padding: 1vw 0.5vw 0vw;
  color: #363253;
}

.contact-info-details a {
  text-decoration: none;
}

.contact-info-media {
  display: flex;
  justify-content: space-around;
  align-content: space-around;
  flex-direction: column;
}

.contact-info-media-wrap {
  display: flex;
  justify-content: space-around;
  align-content: space-around;
  background-color: #9189da;
  border-radius: 100%;
  width: 5vw;
  height: 5vw;
  margin: 1vw 2vw;
}

.contact-info-media .message-whatsapp-feature:hover {
  background-color: rgb(47, 187, 47);
  border-radius: 100%;
}

.contact-info-media-wrap i {
  font-size: 2vw;
  padding: 1.5vw;
  color: white;
}

/*🟣 gallery */

.gallery {
  text-align: center;
  width: 25vw;
  height: 25vw;
}
/* 
.images {
  display: flex;
  justify-content: center;
}

.images i {
  font-size: 5vw;
  margin: 1vw;
  padding: 1vw;
  color: #9189da;
  background-color: #e5e5e5;
} */

.gallery img {
  width: 20vw;
  height: 18vw;
}

/*🟣 map */

.mapouter {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25vw;
  width: 25vw;
}

.gmap_canvas {
  overflow: hidden;
  background: none !important;
  height: 23vw;
  width: 23vw;
}

/*🟡  FOOTER  🟡*/

footer {
  height: 8vh;
  width: 97vw;
  margin: 1vw;
  background-color: #7169b9;
  border-radius: 25px 25px 25px 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: solid white 2px;
}

#footer-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

#footer-box h2 {
  color: white;
}

.footer-nav {
  margin: 1vw;
}

.footer-nav a {
  text-decoration: none;
}

.footer-nav i {
  color: #ffffff;
}

.footer-media {
  font-size: 20px;
  margin: 1vw;
}

.footer-media i {
  color: #ffffff;
  padding: 0.5vw;
}

/* 🔵 ADAPTIV_SCREEN 🔵 */

@media screen and (max-width: 760px) {
  i {
    font-size: 7vw;
  }
  p {
    font-size: 5vw;
  }
  h1 {
    font-size: 10vw;
  }
  h2 {
    font-size: 7vw;
  }

  /* 
  .social-media i {
    display: none;
  } */
  .logo {
    width: 100px;
  }
  #side-nav {
    display: none;
    width: 12vw;
  }

  #side-nav:hover h4 {
    display: none;
  }
  #header {
    height: 65vw;
  }

  .home-header-card {
    margin: 2vw 1vw 1vw 1vw;
    width: 65vw;
    height: 15vw;
  }
  .home-header-card h1 {
    font-size: 8vw;
  }
  .home-header-card h2 {
    font-size: 5vw;
  }
  .home-header-card p {
    display: none;
  }

  .clinic-round-card {
    width: 60vw;
    height: 60vw;
    position: absolute;
    top: 22vw;
  }
  .clinic-round-card p {
    padding: 1vw;
    width: 55vw;
  }

  #clinic {
    height: 40vw;
  }

  .flip-card {
    display: none;
  }

  #about {
    display: none;
  }

  #contact {
    height: 100vh;
    display: flex;
    flex-direction: column;
  }

  #contact-director {
    display: flex;
    flex-direction: column;
  }

  .contact-wrap {
    width: 70vw;
    height: 70vw;
    margin: 5vw;
  }

  .gallery img {
    width: 60vw;
    height: 45vw;
  }

  .contact-info-media {
    width: 25vw;
    height: 70vw;
  }

  .contact-info-media-wrap {
    width: 17vw;
    height: 17vw;
  }

  .contact-info-media-wrap i {
    font-size: 12vw;
  }

  .contact-info-details {
    width: 35vw;
    height: 70vw;
  }

  .contact-info-details h3 {
    font-size: 4.5vw;
  }

  .mapouter {
    display: none;
  }

  #footer-box {
    margin: 1vw;
  }
}
